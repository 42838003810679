<template>
  <v-row>
    <v-col lg="6">
      <s-crud
        add
        @doubleClick="addE($event)"
        addNoFunction
        @add="addE($event)"
        :refreshTable="refreshTable"
        @rowSelected="rowsSelected($event)"
        :config="configNoLevel"
        search
        title="Cargos No Asignados"
        @pdf="pdf($event)"
        searchInput
      >
      </s-crud>
    </v-col>

    <v-dialog
      persistent
      style="no-scroll overflow: hidden !important;"
      v-model="dialog"
      v-if="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <organization-pdf :selected="selected" :TypeNivel="TypeNivel" @closedialogPdf="dialog = false"></organization-pdf>
      <!-- <s-toolbar
        dark
        style="z-index:9999"
        label="Organigrama"
        dense
        close
        @close="dialog = false"
        print
        color="primary"
        @print="exportImg()"
      >
        <template>
          <s-select
            v-model="TypePosition"
            :items="itemsTypePosition"
            style="margin-top:2px;width:170px;"
          ></s-select>
        </template>       
      </s-toolbar>
      <v-card >
        <div class="modal">
          <div class="modal-content" >         
            <div  id="testHtml" ref="testHtml"  >
             
                <table id="table_cabecera"   >
                  <tr>
                    <td>
                      <img  width="200px"
                          src="../../../public/static/logo_sp.png"
                        
                            />
                    </td>
                    <td style=" text-align: center;">
                      {{ 'ORGANIGRAMA DE ' + this.selected.PstName }}
                    </td>
                    <td>
                      <table style="border-collapse: collapse;width: 100%; " >
                        <tr>
                          <td style="text-align: right;">Codigo :</td>
                          <td style="padding-left: 8px;">RH-OG-001</td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">Version : </td>
                          <td style="padding-left: 8px;">01</td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">Fecha de Creacion : </td>
                          <td style="padding-left: 8px;">06/03/2024</td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">Fecha de Revision : </td>
                          <td style="padding-left: 8px;">25/03/2024</td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">Pagina : </td>
                          <td style="padding-left: 8px;">1 de 1</td>
                        </tr>
                        <tr>
                          <td style="text-align: right;">Anexos : </td>
                          <td style="padding-left: 8px;">00</td>
                        </tr> 
                        
                      </table>
                    </td>                

                  </tr>               
                </table>
             
              <div id="imgcol"  class="image-container">               
                   <div class="contenido" style="no-scroll overflow: hidden !important;">                    
                    <org                      
                      :managerNameView="false"
                      :TypePosition="TypePosition"
                      :level="0"
                      :TypeNivel="TypeNivel"
                      :parent="selected"
                      :report="false"
                    ></org>                    
                 </div> 
                
              </div>
            </div>
          </div>
        </div>
      </v-card> --> 
    </v-dialog>

    <v-col lg="6">
      <s-toolbar
        :map="orgv"
        label="Organigrama"
        :save="edit"
        @save="editE()"
        @map="dialog = true"
        :remove="remove"
        @removed="removeE()"
      ></s-toolbar>
      <v-card elevation="0">
        <v-col>
          <v-row justify="center" v-if="edit">
            <v-col lg="3">
              <v-label>Niveles:</v-label>
              <v-btn-toggle v-model="selectedEdit.OrgJump">
                <v-btn fab x-small>
                  <v-icon>0</v-icon>
                </v-btn>
                <v-btn fab x-small>
                  <v-icon>1</v-icon>
                </v-btn>
                <v-btn fab x-small>
                  <v-icon>2</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col lg="3" style="">
              <s-switch
                label="Es Asistente"
                v-model="selectedEdit.OrgIsAsistent"
              ></s-switch>
            </v-col>
            <v-col lg="3" style="">
              <s-select 
                label="Datos Imprimir" 
                :items="itemsImpresion"
                v-model="TypeNivel">
                
              </s-select>
            </v-col>
          </v-row>
        </v-col>
        <v-treeview
          item-key="OrgID"
          item-text="PstName"
          item-children="GenPositionChild"
          selectable
          selection-type="independent"
          v-model="active"
          open-all
          :open.sync="open"
          selected-color="accent"
          return-object
          open-on-click
          :items="items"
        ></v-treeview>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
html2canvas;
import html2canvas from "html2canvas";
import ServiceOrganization from "./../../services/HumanResource/OrganizationLevelService";
import Org from "./../../components/Org/Org.vue";
import jsPDF from 'jspdf';
import OrganizationPdf from "./OrganizationPdf.vue";
import 'jspdf-autotable'

export default {
  name: "Organization",
  components: { Org, OrganizationPdf },
  mounted() {},
  data() {return {
    itemsTypePosition: [
      { text: "SOLO FIJOS", value: 1 },
      { text: "TODOS", value: 0 },
    ],
    itemsImpresion:[
      {text: "Nivel 1", value:1},
      {text: "TODOS", value: 0 },
    ],
    TypePosition: 1,
    selectedEdit: null,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    open: [],
    refreshTable: false,
    edit: false,
    remove: false,
    orgv: false,
    active: [],
    selectedNoLevel: null,
    org: {
      PstID: null,
      PstParentID: null,
    },
    configNoLevel: {
      model: {
        PstName: "string",
        PstID: "ID",
      },
      service: ServiceOrganization,
      headers: [
        { text: "ID", value: "PstID" },
        { text: "Cargo", value: "PstName" },
      ],
    },

    items: [],
    TypeNivel: 0,
  }},
  computed: {
    selected() {
      if (!this.active.length) {
        return undefined;
      } else this.selectedEdit = { ...this.active[0] };
      this.active = [this.active[this.active.length - 1]];  
      this.PstName = this.active[0].PstName;
      
      return this.active[0];
    },
  },
  watch: {
    TypePosition() {},
    active() {
      if (this.selected != undefined) {
        this.edit = true;
        this.remove = true;
        this.orgv = true;
      } else {
        this.edit = false;
        this.remove = false;
        this.orgv = false;
      }
    },
  },
  methods: {
    editE() {
      this.$fun.alert("Seguro de Guardar", "question").then((val) => {
        if (val.value) {
          ServiceOrganization.save(
            this.selectedEdit,
            this.$fun.getUserID()
          ).then((r) => {
            this.load();
            this.$fun.alert("Guardado Correctamente", "success");
          });
        }
      });
    },
    exportImg() {
      // html2canvas(document.getElementById("org")).then((canvas) => {
      //   let enlace = document.createElement("a");
      //   enlace.download = "Organigrama-SunshineExport.png";
      //   enlace.href = canvas.toDataURL();
      //   //  enlace.ImageData();
      //   enlace.click();
      // });
      
      // html2canvas(document.getElementById("org")).then((canvas) => {
      // const PstName =  'ORGANIGRAMA DE ' + this.selected.PstName;
      
        // const fontSize = 8;      
      
        
        // const pageSize = doc.internal.pageSize;
        // const pageWidth = pageSize.width;
        // const tableWidth = 50;

        // const tableX = pageWidth - tableWidth - 10; 
        // const tableY = 10;
        
        // const imgcabecera = '../../../../public/static/logo_sp.png';

        // doc.addImage(imgcabecera, 'JPEG', 20, 10, 50, 100, '', 'FAST', true);
       
        // doc.text( PstName, 10 , 10);
        // doc.autoTable({
        //   startY: 5,
        //   head: [['Codigo : ', 'RH-OG-001']],
        //   body: [['Version : ', '01'],['Fecha de Creacion : ', '06/03/2024'],['Fecha de Revision : ', '25/03/2024'],['Pagina : ', '1 de 1'],['Anexos : ', '00']],
        //   theme: 'plain',
        //   margin: {left:tableX, top:tableY},
        //   tableWidth: 'wrap',
          
        //   columnStyles: {             
        //     0: {  halign: 'right' }
        //   },
        //   headStyles: { halign: 'right'},
        //   styles: { fontSize: fontSize, cellPadding: 0 }, 
        // });

        //ULTIMO
        // const contentToExport = document.getElementById('testHtml');

        // html2canvas(contentToExport, {
        //   scale: 2,
        //   logging: true,
        //   allowTainted: true,
        //   useCORS: true,
        // }).then(function(canvas){

        // canvas.style.border = "none";

        // const imgData = canvas.toDataURL('image/png');
        
        // const doc = new jsPDF({
        //     unit: 'mm',
        //     format: 'a4',
        //     orientation: 'landscape'
        // });

        // const imgWidth = 277; //260 + 20;
        // const imgHeight = canvas.height * imgWidth / canvas.width;
        // const imgX = 10;
        // const imgY = 10;
        // doc.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight, '', 'FAST', true);

        // // doc.save('documento.pdf');
        // doc.output('dataurlnewwindow');
        //ULTIMO
      // })     
      // const html = this.$refs.testHtml.innerHTML;
      const contentToExport = this.$refs.testHtml;

      const doc = new jsPDF({
        unit: 'mm',
        format: 'a4',
        orientation: 'landscape'
      });

      const fontSize = 8;
      doc.setFontSize(fontSize);
      const margins = { top: 5, bottom: 10, left: 10, right: 10 };

      doc.html(contentToExport, {
        callback:() => {
          doc.output('dataurlnewwindow');
        },
        x: margins.left,
        y: margins.top,
        html2canvas:{
          scale: 0.25
        }
      });

    },
    addE(item) {
      let parentID = null;
      if(this.selected == null){this.$fun.alert('Debe Seleccionar un cargo en organigrama','warning')}
      else{
      if (this.selected == undefined) {
        parentID = null;
      } else parentID = this.selected.PstID;

      if (item != null) {
        let obj = {
          PstID: this.selectedNoLevel.PstID,
          PstParentID: parentID,
        };
        ServiceOrganization.save(obj, localStorage.getItem("UsrID")).then(
          () => {
            this.refreshTable = !this.refreshTable;
            this.load();
          }
        );
      }}
    },

    removeE() {
      let obj = Object.assign({}, this.selected);
      obj.SecStatus = 0;
      ServiceOrganization.save(obj, localStorage.getItem("UsrID")).then(() => {
        this.refreshTable = !this.refreshTable;
        this.load();
      });
    },
    rowsSelected(items) {
      if (items.length > 0) this.selectedNoLevel = items[0];
      else this.selectedNoLevel = null;
    },
    load() {
      this.active = [];
      this.open = [];
      this.items = [];
      let obj = { PstParentID: null, PstID: null };
      ServiceOrganization.listLevel(obj).then((r) => {
        this.items = r.data;
        
        this.items.forEach((element) => {
          this.recursiveTree(element);
        });
        this.active = [];
      });
    },
    recursiveTree(item) {
      this.open.push(item);
      
      if (item.GenPositionChild.length > 0)
        item.GenPositionChild.forEach((element) => {
          this.recursiveTree(element);
        });
    },
  },
  created() {
    this.load();
  },
  mounted() {},
};
</script>

<style>
body, html{
  margin:0;
  padding: 0;
 
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  
}

.modal-content {
  background-color: #fff;
  width: 297mm; /* Ancho de documento A4 */
  height: 210mm; /* Alto de documento A4 */
  padding: 0mm 0mm; /* Márgenes internos */
  border: 1px solid #000; 
  
}

#table_cabecera
{
  position: relative;
  top: 0;
  width: 290mm;
  background-color: #fff;
  border-collapse: collapse;
}

.contenido{
  margin-top: 100px;
}
</style>
